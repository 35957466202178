import React, { useEffect, useRef } from "react";
import "./Styles.scss";
import StageSection from "./StageSection/StageSection.js";
import FundNameSection from "./FundNameSection/FundNameSection.js";
import TargetPerformanceSection from "./TargetPerformanceSection/TargetPerformanceSection.js";
import AllocationGraphSection from "./AllocationGraphSection/AllocationGraphSection.js";
import { useHistory } from "react-router-dom";
import FlorenceCapTargetPerformanceSection from "./TargetPerformanceSection/FlorenceCapTargetPerformanceSection.js/FlorenceCapTargetPerformanceSection.js";
import QvinTargetPerformanceSection from "./TargetPerformanceSection/QvinTargetPerformanceSection.js";
import LVTargetPerformanceSection from "./TargetPerformanceSection/LVTargetPerformanceSection.js";
import WandTargetPerformanceSection from "./TargetPerformanceSection/WandTargetPerformanceSection.js";
import AssemblyTargetPerformanceSection from "./TargetPerformanceSection/AssemblyTargetPerformanceSection.js";
import SunshineLakeTargetPerformanceSection from "./TargetPerformanceSection/SunshineLakeTargetPerformanceSection.js";
import NscaleTargetPerformanceSection from "./TargetPerformanceSection/NscaleTargetPerformanceSection.js";
import JukeboxTargetPerformanceSection from "./TargetPerformanceSection/JukeboxTargetPerformanceSection.js";
import DOrbitTargetPerformanceSection from "./TargetPerformanceSection/DOrbitTargetPerformanceSection.js";

function FundCard({ fund, isFeeder }) {
  let history = useHistory();
  const hostName = window.location.hostname;

  const stageMap = {
    STRUCTURED_CREDIT: "Private Credit",
    CLO_MANAGEMENT: "CLO Management",
    DIRECT_LANDING: "Direct Landing",
    PRIVATE_EQUITY: "Private Equity",
    CO_INVESTMENT: "Co-Investment",
    FUND_OF_FUNDS: "Fund of Funds",
    BUYOUT: "Buyout",
    GROWTH: "Growth",
    CREDIT: "Credit",
    VENTURE_CAPITAL: "Venture",
  };
  const fundRef = useRef(null);
  console.log(fund.link);

  useEffect(() => {
    console.log("Is feeder is: " + isFeeder);
  }, [isFeeder]);

  useEffect(() => {
    console.log("Fund is: " + JSON.stringify(fund));
  }, [fund]);

  return (
    <div className="col-xl-4 col-md-6 col-12">
      <div className="row g-0 ps-3 pe-3 h-100">
        <div
          ref={fundRef}
          className="fund-card-new active-fund-card-new"
          onClick={() => {
            if (
              !["Closed"].includes(fund.fundInvestmentStatus) ||
              fund.enableRequestAllocation === true
            ) {
              history.push("/invest/" + fund.link);
            }
          }}
        >
          <div className="col-12">
            <FundNameSection
              fundName={fund.fundName}
              firmName={fund.firm}
              logoLink={isFeeder === false ? fund.logoLink : fund.fundLogoLink}
            />
            {fund && fund.link === "spv_vii" ? (
              <FlorenceCapTargetPerformanceSection
                targetIRR={fund.targetIRR}
                strategy={stageMap[fund.strategy]}
                targetReturnMultiple={fund.fundTerms.target_return_multiple}
                minimum={fund.minimumInvestment}
                stage={fund.fundInvestmentStatus}
              />
            ) : fund && fund.link === "spv_viii" ? (
              <QvinTargetPerformanceSection
                stage={fund.fundInvestmentStatus}
                targetIRR={fund.targetIRR}
                strategy={stageMap[fund.strategy]}
                minimum={fund.minimumInvestment}
                targetReturnMultiple={fund.fundTerms.target_return_multiple}
              />
            ) : fund && fund.link === "laude_venture_i" ? (
              <LVTargetPerformanceSection
                stage={fund.fundInvestmentStatus}
                targetIRR={fund.targetIRR}
                strategy={stageMap[fund.strategy]}
                minimum={fund.minimumInvestment}
                targetReturnMultiple={fund.fundTerms.target_return_multiple}
              />
            ) : fund && fund.link === "wand_ai" ? (
              <WandTargetPerformanceSection
                stage={fund.fundInvestmentStatus}
                targetIRR={fund.targetIRR}
                strategy={stageMap[fund.strategy]}
                minimum={fund.minimumInvestment}
                targetReturnMultiple={fund.fundTerms.target_return_multiple}
              />
            ) : fund && fund.link === "spv_xi" ? (
              <AssemblyTargetPerformanceSection
                stage={fund.fundInvestmentStatus}
                targetIRR={fund.targetIRR}
                strategy={stageMap[fund.strategy]}
                minimum={fund.minimumInvestment}
                targetReturnMultiple={fund.fundTerms.target_return_multiple}
              />
            ) : fund && fund.link === "spv_x" ? (
              <NscaleTargetPerformanceSection
                stage={fund.fundInvestmentStatus}
                targetIRR={fund.targetIRR}
                strategy={stageMap[fund.strategy]}
                minimum={fund.minimumInvestment}
                targetReturnMultiple={fund.fundTerms.target_return_multiple}
              />
            ) : fund && fund.link === "slai_i" ? (
              <SunshineLakeTargetPerformanceSection
                stage={fund.fundInvestmentStatus}
                targetIRR={fund.targetIRR}
                strategy={stageMap[fund.strategy]}
                minimum={fund.minimumInvestment}
                targetReturnMultiple={fund.fundTerms.target_return_multiple}
              />
            ) : fund && fund.link === "spv_xii" ? (
              <JukeboxTargetPerformanceSection
                stage={fund.fundInvestmentStatus}
                targetIRR={fund.targetIRR}
                strategy={stageMap[fund.strategy]}
                minimum={fund.minimumInvestment}
                targetReturnMultiple={fund.fundTerms.target_return_multiple}
              />
            ) : fund && fund.link === "icp" ? (
              <DOrbitTargetPerformanceSection
                stage={fund.fundInvestmentStatus}
                targetIRR={fund.targetIRR}
                strategy={stageMap[fund.strategy]}
                minimum={fund.minimumInvestment}
                targetReturnMultiple={fund.fundTerms.target_return_multiple}
              />
            ) : (
              <TargetPerformanceSection
                stage={fund.fundInvestmentStatus}
                targetIRR={fund.targetIRR}
                strategy={stageMap[fund.strategy]}
                minimum={fund.minimumInvestment}
                targetReturnMultiple={fund.fundTerms.target_return_multiple}
              />
            )}
            <AllocationGraphSection
              stage={fund.fundInvestmentStatus}
              allocationLeft={fund.amountInvested / fund.fundSize}
            />
            <StageSection stage={fund.fundInvestmentStatus} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default FundCard;
