import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../GlobalContexts/UserContext.js";
import "../../Styles/Components/Header/Header.css";
// import { Auth } from "aws-amplify";
// import Navbar from "react-bootstrap/Navbar";
// import Nav from "react-bootstrap/Nav";
// import NavDropdown from "react-bootstrap/NavDropdown";
// import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import EllipseDropdown from "./EllipseDropdown/EllipseDropdown.js";
import Media from "react-media";
import { useMediaQuery } from "react-responsive";

function Header({ activePage }) {
  const [loggedInUser, setLoggedInUser] = useState(null);
  const { user, setUser } = useContext(UserContext);

  const [investSelected, setInvestSelected] = useState(false);
  const [portfolioSelected, setPortfolioSelected] = useState(false);
  const [messagesSelected, setMessagesSelected] = useState(false);
  const [learnSelected, setLearnSelected] = useState(false);
  const [faqSelected, setFaqSelected] = useState(false);
  const [pageSelected, setPageSelected] = useState("");
  const [investmentAccountSelected, setInvestmentAccountSelected] =
    useState(false);
    const [dashboardSelected, setDashboardSelected] =
    useState(false);
  // const [showDropdown, setShowDropdown] = useState(false);
  const [text, setText] = useState("");
  const [defaultKey, setDefaultKey] = useState([]);

  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

  // let history = useHistory();
  useEffect(() => {
    var items = [];
    items.push(text);
    setDefaultKey(items);
  }, [text]);

  useEffect(() => {
    if (activePage === "invest") {
      setPortfolioSelected(false);
      setMessagesSelected(false);
      setLearnSelected(false);
      setFaqSelected(false);
      // setInvestmentAccountsSelected(false);
      setInvestSelected(true);
      setInvestmentAccountSelected(false);
      setDashboardSelected(false);
      setDefaultKey([]);
    } else if (activePage === "portfolio") {
      setInvestSelected(false);
      setMessagesSelected(false);
      setLearnSelected(false);
      setFaqSelected(false);
      // setInvestmentAccountsSelected(false);
      setPortfolioSelected(true);
      setDashboardSelected(false);
      setInvestmentAccountSelected(false);
      setDefaultKey([]);
    } else if (activePage === "messages") {
      setInvestSelected(false);
      setLearnSelected(false);
      setPortfolioSelected(false);
      setFaqSelected(false);
      setDashboardSelected(false);
      // setInvestmentAccountsSelected(false);
      setMessagesSelected(true);
      setInvestmentAccountSelected(false);
      setDefaultKey([]);
    } else if (activePage === "learn") {
      setInvestSelected(false);
      setPortfolioSelected(false);
      setMessagesSelected(false);
      setFaqSelected(false);
      setDashboardSelected(false);
      // setInvestmentAccountsSelected(false);
      setLearnSelected(true);
      setInvestmentAccountSelected(false);
      setDefaultKey([]);
    } else if (activePage === "faq") {
      setInvestSelected(false);
      setPortfolioSelected(false);
      setMessagesSelected(false);
      setLearnSelected(false);
      setDashboardSelected(false);
      // setInvestmentAccountsSelected(false);
      setFaqSelected(true);
      setInvestmentAccountSelected(false);
      setDefaultKey([]);
    } else if (activePage === "investment-accounts") {
      setInvestSelected(false);
      setPortfolioSelected(false);
      setMessagesSelected(false);
      setLearnSelected(false);
      setFaqSelected(false);
      setDashboardSelected(false);
      setInvestmentAccountSelected(true);
      // setInvestmentAccountsSelected(true);
      setDefaultKey([]);
    }
    else if (activePage === "dashboard") {
      setInvestSelected(false);
      setPortfolioSelected(false);
      setMessagesSelected(false);
      setLearnSelected(false);
      setFaqSelected(false);
      setDashboardSelected(true);
      setInvestmentAccountSelected(false);
      // setInvestmentAccountsSelected(true);
      setDefaultKey([]);
    }
    setPageSelected(activePage)
  }, [activePage]);

  return (
    <>
      <nav
        //
        className="navbar navbar-expand-lg navbar-light bg-light pt-3 pb-3 fixed-top header-style"
        style={{ backgroundColor: "#ffffff !important" }}
      >
        <div className="container-fluid">
          <Link to="/invest">
            <div className="navbar-brand">
              <div className="container-fluid">
                <img
                  src="https://d1swyfveypj49y.cloudfront.net/AquaPlatformHeaderLogo.svg"
                  alt="logo"
                  height="36"
                  loading="lazy"
                ></img>
              </div>
            </div>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto" style={{ width: "50%" }}>
              <div className="row g-0 w-100">
                <div className="col-12">
                  <div className="row g-0 w-100">
                    <div className="col-md col-12">
                      <li className="nav-item">
                        <div className="row g-0 h-100 w-100 justify-content-md-center justify-content-start">
                          <div className="align-self-center justify-self-center">
                            <Link to="/invest">
                              <div className="nav-link text-center w-100">
                                <span
                                  className={
                                    investSelected
                                      ? "nav-text-selected"
                                      : "nav-text"
                                  }
                                >
                                  Invest
                                </span>
                              </div>
                            </Link>
                          </div>
                          {!isMobile && (
                            <div
                              className={
                                investSelected
                                  ? "title-bar-active align-self-end justify-self-center"
                                  : "title-bar-inactive align-self-end justify-self-center"
                              }
                              style={{ width: "56px" }}
                            ></div>
                          )}
                        </div>
                      </li>
                    </div>
                    <div className="col-md col-12">
                      <li className="nav-item">
                        <div className="row g-0 h-100 w-100 justify-content-center">
                          <div className="align-self-center justify-self-center">
                            <Link to="/portfolio">
                              <div className="nav-link text-center w-100">
                                <span
                                  className={
                                    portfolioSelected
                                      ? "nav-text-selected"
                                      : "nav-text"
                                  }
                                >
                                  Portfolio
                                </span>
                              </div>
                            </Link>
                          </div>
                          {!isMobile && (
                            <div
                              className={
                                portfolioSelected
                                  ? "title-bar-active align-self-end justify-self-center"
                                  : "title-bar-inactive align-self-end justify-self-center"
                              }
                              style={{ width: "70px" }}
                            ></div>
                          )}
                        </div>
                      </li>
                    </div>
                    <div className="col-md col-12">
                      <li className="nav-item">
                        <div className="row g-0 w-100 h-100 justify-content-center">
                          <Link to="/investment-accounts">
                            <div className="nav-link text-center w-100 text-nowrap">
                              <span
                                className={
                                  investmentAccountSelected
                                    ? "nav-text-selected"
                                    : "nav-text"
                                }
                              >
                                Investment Accounts
                              </span>
                            </div>
                          </Link>
                          {!isMobile && (
                            <div
                              className={
                                investmentAccountSelected
                                  ? "title-bar-active align-self-end justify-self-center"
                                  : "title-bar-inactive align-self-end justify-self-center"
                              }
                              style={{ width: "174px" }}
                            ></div>
                          )}
                        </div>
                      </li>
                    </div>
                    {/* (user !== null && (user.id.endsWith('@sunshinelake.vc') || user.id.endsWith('@investwithaqua.com'))) && <div className="col-md col-12">
                      <li className="nav-item">
                        <div className="row g-0 w-100 h-100 justify-content-center">
                          <Link to="/admin">
                            <div className="nav-link text-center w-100 text-nowrap">
                              <span
                                className={
                                  dashboardSelected
                                    ? "nav-text-selected"
                                    : "nav-text"
                                }
                              >
                                Dashboard
                              </span>
                            </div>
                          </Link>
                          {!isMobile && (
                            <div
                              className={
                                dashboardSelected
                                  ? "title-bar-active align-self-end justify-self-center"
                                  : "title-bar-inactive align-self-end justify-self-center"
                              }
                              style={{ width: "120px" }}
                            ></div>
                          )}
                        </div>
                      </li>
                    </div> */}
                    <div className="col-md col-12">
                      <li className="nav-item">
                        <div className="row g-0 w-100 h-100 justify-content-center">
                          <Link to="/capital-calls">
                            <div className="nav-link text-center w-100 text-nowrap">
                              <span
                                className={
                                  pageSelected === "capital-calls"
                                    ? "nav-text-selected"
                                    : "nav-text"
                                }
                              >
                                Capital Calls
                              </span>
                            </div>
                          </Link>
                          {!isMobile && (
                            <div
                              className={
                                pageSelected === "capital-calls"
                                  ? "title-bar-active align-self-end justify-self-center"
                                  : "title-bar-inactive align-self-end justify-self-center"
                              }
                              style={{ width: "174px" }}
                            ></div>
                          )}
                        </div>
                      </li>
                    </div>
                    {/* <div className="col-md col-12">
                      <li className="nav-item">
                        <div className="row g-0 w-100 h-100 justify-content-center">
                          <div className="align-self-center justify-self-center">
                            <Link to="/messages">
                              <div className="nav-link text-center w-100">
                                <span
                                  className={
                                    messagesSelected
                                      ? "nav-text-selected"
                                      : "nav-text"
                                  }
                                >
                                  Messages
                                </span>
                              </div>
                            </Link>
                          </div>
                          {!isMobile && (
                            <div
                              className={
                                messagesSelected
                                  ? "title-bar-active align-self-end justify-self-center"
                                  : "title-bar-inactive align-self-end justify-self-center"
                              }
                              style={{ width: "80px" }}
                            ></div>
                          )}
                        </div>
                      </li>
                    </div> */}
                    {/* <div className="col-md col-12">
                      <li className="nav-item">
                        <div className="row g-0 w-100 h-100 justify-content-center">
                          <div className="align-self-center justify-self-center">
                            <Link to="/learn">
                              <div className="nav-link text-center w-100">
                                <span
                                  className={
                                    learnSelected
                                      ? "nav-text-selected"
                                      : "nav-text"
                                  }
                                >
                                  Learn
                                </span>
                              </div>
                            </Link>
                          </div>
                          {!isMobile && (
                            <div
                              className={
                                learnSelected
                                  ? "title-bar-active align-self-end justify-self-center"
                                  : "title-bar-inactive align-self-end justify-self-center"
                              }
                              style={{ width: "46px" }}
                            ></div>
                          )}
                        </div>
                      </li>
                    </div> */}
                    {/* <div className="col-md col-12">
                      <li className="nav-item">
                        <div className="row g-0 w-100 h-100 justify-content-center">
                          <Link to="/faq">
                            <div className="nav-link text-center w-100">
                              <span
                                className={
                                  faqSelected ? "nav-text-selected" : "nav-text"
                                }
                              >
                                FAQs
                              </span>
                            </div>
                          </Link>
                          {!isMobile && (
                            <div
                              className={
                                faqSelected
                                  ? "title-bar-active align-self-end justify-self-center"
                                  : "title-bar-inactive align-self-end justify-self-center"
                              }
                              style={{ width: "43px" }}
                            ></div>
                          )}
                        </div>
                      </li>
                    </div> */}
                  </div>
                </div>
              </div>
            </ul>
            <Media queries={{ large: { minWidth: 992 } }}>
              {(matches) =>
                matches.large ? (
                  <div className="ms-lg-auto me-lg-4 ms-auto me-auto text-lg-start text-center">
                    <span className="account-name-text">
                      {user
                        ? user.investorSummaryData.first_name +
                          " " +
                          user.investorSummaryData.last_name
                        : ""}
                    </span>
                  </div>
                ) : null
              }
            </Media>

            <div className="row mt-lg-0">
              <div className="ms-auto me-auto justify-self-center">
                {/* <Container className="me-4"> */}
                <div class="nav-item dropdown d-flex justify-content-center">
                  <EllipseDropdown
                    text={text}
                    setText={setText}
                    defaultKey={defaultKey}
                  />
                </div>
                {/* <div
                    className="nav-link"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                    onClick={() => setShowDropdown(!showDropdown)}
                  >
                    <img src="https://d1swyfveypj49y.cloudfront.net/Ellipse.svg" alt="ellipse" height="25"></img>
                  </div> */}
                {/* <div
                    className={
                      showDropdown
                        ? "dropdown-menu dropdown-menu-start dropdown-border-style show"
                        : "dropdown-menu dropdown-menu-start dropdown-border-style"
                    }
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <Link to="/profile">
                      <div className="dropdown-item">
                        <img className="me-2" src="https://d1swyfveypj49y.cloudfront.net/user.svg" alt="user"></img>
                        <span className="dropdown-text">Profile</span>
                      </div>
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link to="/documents">
                      <div className="dropdown-item">
                        <img className="me-2" src="https://d1swyfveypj49y.cloudfront.net/File.svg" alt="File"></img>
                        <span className="dropdown-text">Documents</span>
                      </div>
                    </Link>
                    <Link to="/transactions">
                      <div className="dropdown-item">
                        <img
                          className="me-2"
                          src="https://d1swyfveypj49y.cloudfront.net/Wallet.svg"
                          alt="Wallet"
                        ></img>
                        <span className="dropdown-text">Transactions</span>
                      </div>
                    </Link>
                    <Link to="/referrals">
                      <div className="dropdown-item">
                        <img
                          className="me-2"
                          src="https://d1swyfveypj49y.cloudfront.net/users.svg"
                          alt="users"
                        ></img>
                        <span className="dropdown-text">Referrals</span>
                      </div>
                    </Link>
                    <Link to="/messages">
                      <div className="dropdown-item">
                        <img
                          className="me-2"
                          src="https://d1swyfveypj49y.cloudfront.net/Line.svg"
                          alt="message"
                        ></img>
                        <span className="dropdown-text">Contact Us</span>
                      </div>
                    </Link>
                    <div className="dropdown-divider"></div>
                    <div
                      className="dropdown-item"
                      onClick={async () => logoutUser()}
                    >
                      <img
                        className="me-2"
                        src="https://d1swyfveypj49y.cloudfront.net/Logout.svg"
                        alt="Logout"
                      ></img>
                      <span className="dropdown-logout-text">Logout</span>
                    </div>
                  </div> */}
              </div>
              {/* </Container> */}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
export default Header;
