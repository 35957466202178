import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../GlobalContexts/UserContext.js";
import { isEmailValid } from "../../../GlobalFunctions/globalFunctions.js";
import { Auth } from "aws-amplify";
import {
  getAccountList,
  getInvestorSummary,
  updateIdentityId,
  getInvestorProfile,
} from "../../../Api/ProfileApi.js";
import StyledPasswordInputField from "../../../GlobalComponents/InputField/StyledPasswordInputField/StyledPasswordInputField.js";
import StyledTextInputField from "../../../GlobalComponents/InputField/StyledTextInputField/StyledTextInputField.js";
import AlertSnackBar from "../../../GlobalComponents/AlertSnackBar/AlertSnackBar.js";
import "./LoginPageStyles/LoginPageStyles.css";
import { LocationSearching } from "@material-ui/icons";

function LoginPage(props) {
  let history = useHistory();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);
  const { setUser } = useContext(UserContext);

  const [accountCreationSuccessful, setAccountCreationSuccessful] = useState(
    location !== null &&
      location !== undefined &&
      location.state !== null &&
      location.state !== undefined &&
      location.state.accountCreationSuccessful !== null &&
      location.state.accountCreationSuccessful !== undefined
      ? location.state.accountCreationSuccessful
      : false
  );

  const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(
    location !== null &&
      location !== undefined &&
      (location.state !== null) & (location.state !== undefined) &&
      location.state.passwordResetSuccessful !== null &&
      location.state.passwordResetSuccessful !== undefined
      ? location.state.passwordResetSuccessful
      : false
  );

  const [emailConfirmationSuccessful, setEmailConfirmationSuccessful] =
    useState(
      location !== null &&
        location !== undefined &&
        location.state !== null &&
        location.state !== undefined &&
        location.state.emailConfirmationSuccessful !== null &&
        location.state.emailConfirmationSuccessful !== undefined
        ? location.state.emailConfirmationSuccessful
        : false
    );

  const [errorSnackBarState, setErrorSnackbarState] = useState(false);
  const [invalidDomainError, setInvalidDomainError] = useState(false);
  const [domains, setDomains] = useState("");

  const redirect = "";

  useEffect(() => {
    setLoginError("");
  }, [email, password]);

  useEffect(() => {}, [redirect]);

  useEffect(() => {
    setEmailError("");
  }, [email]);

  useEffect(() => {
    setPasswordError("");
  }, [password]);

  const signIn = async () => {
    let isDataValid = true;

    if (email.length === 0) {
      setEmailError("Please enter an email");
      isDataValid = false;
    } else if (!isEmailValid(email)) {
      setEmailError("Please enter a valid email");
      isDataValid = false;
    }

    if (password.length === 0) {
      setPasswordError("Please enter a password");
      isDataValid = false;
    }

    if (!isDataValid) {
      return;
    }
    setLoading(true);
    try {
      const logUser = await Auth.signIn(
        email.toString().toLowerCase(),
        password
      );
      try {
        const identityId = await Auth.currentCredentials();
        await updateIdentityId(identityId.identityId);

        try {
          const userID = email.toString().toLowerCase();
          const userProfile = await getInvestorProfile(); // profile-pii
          console.log(userProfile);
          if (userProfile && userProfile.data.valid_domains) {
            console.log(userProfile.data.valid_domains);
            setDomains(
              userProfile.data.valid_domains
                .map((e) => e.ind_platform_domain)
                .join(" - ")
            );
            setInvalidDomainError(true);
            setLoading(false);
            return;
          }
          const investorSummary = await getInvestorSummary();
          const accountList = await getAccountList();
          var investorPrimaryAddressLine1 = "";
          var investorPrimaryAddressLine2 = "";
          var investorPrimaryCity = "";
          var investorPrimaryState = "";
          var investorPrimaryCountry = "";
          var investorPrimaryPostalCode = "";
          var investorPrimaryAddressType = "";

          var investorSecondaryAddressLine1 = "";
          var investorSecondaryAddressLine2 = "";
          var investorSecondaryCity = "";
          var investorSecondaryState = "";
          var investorSecondaryCountry = "";
          var investorSecondaryPostalCode = "";
          var investorSecondaryAddressType = "";

          if (userProfile.data[0].investor_addresses.length === 1) {
            investorPrimaryAddressLine1 =
              userProfile.data[0].investor_addresses[0].address_line_1;
            investorPrimaryAddressLine2 =
              userProfile.data[0].investor_addresses[0].address_line_2;
            investorPrimaryCity =
              userProfile.data[0].investor_addresses[0].city;
            investorPrimaryState =
              userProfile.data[0].investor_addresses[0].state;
            investorPrimaryCountry =
              userProfile.data[0].investor_addresses[0].country;
            investorPrimaryPostalCode =
              userProfile.data[0].investor_addresses[0].postal_code;
            investorPrimaryAddressType =
              userProfile.data[0].investor_addresses[0].address_type;
          } else if (userProfile.data[0].investor_addresses.length > 1) {
            userProfile.data[0].investor_addresses.forEach(
              (investor_address) => {
                if (investor_address.address_type === "M") {
                  investorSecondaryAddressLine1 =
                    investor_address.address_line_1;
                  investorSecondaryAddressLine2 =
                    investor_address.address_line_2;
                  investorSecondaryCity = investor_address.city;
                  investorSecondaryState = investor_address.state;
                  investorSecondaryCountry = investor_address.country;
                  investorSecondaryPostalCode = investor_address.postal_code;
                  investorSecondaryAddressType = investor_address.address_type;
                } else if (
                  investor_address.address_type === "R" ||
                  investor_address === "RM"
                ) {
                  investorPrimaryAddressLine1 = investor_address.address_line_1;
                  investorPrimaryAddressLine2 = investor_address.address_line_2;
                  investorPrimaryCity = investor_address.city;
                  investorPrimaryState = investor_address.state;
                  investorPrimaryCountry = investor_address.country;
                  investorPrimaryPostalCode = investor_address.postal_code;
                  investorPrimaryAddressType = investor_address.address_type;
                }
              }
            );
          }
          const isAuthenticated = true;
          setUser({
            id: userID,
            userProfileData: userProfile.data[0],
            accountListData: accountList.data,
            selectedAccountID:
              accountList && accountList.data.length !== 0
                ? accountList.data[0].id
                : "",
            investorSummaryData: investorSummary.data[0],
            investorPrimaryAddressLine1: investorPrimaryAddressLine1,
            investorPrimaryAddressLine2: investorPrimaryAddressLine2,
            investorPrimaryCity: investorPrimaryCity,
            investorPrimaryState: investorPrimaryState,
            investorPrimaryCountry: investorPrimaryCountry,
            investorPrimaryPostalCode: investorPrimaryPostalCode,
            investorPrimaryAddressType: investorPrimaryAddressType,
            investorSecondaryAddressLine1: investorSecondaryAddressLine1,
            investorSecondaryAddressLine2: investorSecondaryAddressLine2,
            investorSecondaryCity: investorSecondaryCity,
            investorSecondaryState: investorSecondaryState,
            investorSecondaryCountry: investorSecondaryCountry,
            investorSecondaryPostalCode: investorSecondaryPostalCode,
            investorSecondaryAddressType: investorSecondaryAddressType,
            isAuthenticated: isAuthenticated,
          });
          setLoading(false);
          // }
        } catch (error) {
          if (process.env.DEBUG) console.log("There is an error.");
          if (process.env.DEBUG) console.error(error);
          setLoading(false);
          setUser(null);
        }
      } catch (error) {
        setLoading(false);
        if (process.env.DEBUG) console.error(error);
      }
    } catch (err) {
      if (process.env.DEBUG) console.error(err);
      console.log(err);
      if (
        err &&
        err.code !== null &&
        err.code !== undefined &&
        err.code === "UserNotConfirmedException"
      ) {
        try {
          await Auth.resendSignUp(email);
          history.push({
            pathname: "/verify-email",
            state: { email: email },
          });
        } catch (err) {
          setLoading(false);
          setErrorSnackbarState(true);
          console.log(err);
        }
      } else if (
        err &&
        err.code !== null &&
        err.code !== undefined &&
        err.code === "NotAuthorizedException"
      ) {
        setPasswordError("Incorrect username or password");
      }
      setLoading(false);
      setLoginError("Error signing in.");
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        signIn();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [email, password]);

  useEffect(() => {
    console.log("Hostname is: " + window.location.hostname);
  }, []);

  return (
    <div className="container-fluid w-100 g-0 ms-0 me-0 overflow-hidden fillScreen align-items-center justify-content-center d-flex">
      <div className="row g-0 w-100 align-items-center justify-content-center">
        <div
          className="col-6 h-100 d-none d-sm-none d-md-none d-lg-block"
          style={{
            backgroundColor: "#F0F4FD",
            zIndex: 1,
          }}
        >
          <img
            src="https://d1swyfveypj49y.cloudfront.net/AquaNewLoginImage.svg"
            style={{
              height: "100vh",
              backgroundSize: "cover !important",
              zIndex: 2,
            }}
            alt="community"
          ></img>
        </div>

        <div
          className="col-xxl-6 col-xl-6 col-lg-6 col-10 mt-auto mb-auto align-items-center"
          style={{ zIndex: 3, backgroundColor: "#f7f7fa", height: "100vh" }}
        >
          <div className="container-fluid g-0 ms-5 pe-3 h-100 align-items-center mt-auto mb-auto pt-auto pb-auto">
            <div className="row g-0 w-100 align-items-center h-100 mt-auto mb-auto pt-auto pb-auto">
              <div className="col-12 align-items-center mt-auto mb-auto pt-auto pb-auto">
                {/* <div className="row g-0 w-100 mb-2 align-self-center">
                  <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                    <span className="sized-down-welcome-text">
                      Welcome to Aqua
                    </span>
                  </div>
                </div>
                <div className="row g-0 w-100 mb-4">
                  <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                    <span className="sized-down-enter-text">
                      Please sign in to use the platform
                    </span>
                  </div>
                </div> */}
                <div className="row g-0 w-100 mb-4">
                  <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                    <img
                      src="https://d1swyfveypj49y.cloudfront.net/AquaPlatformHeaderLogo.svg"
                      alt="logo"
                      height="36"
                      loading="lazy"
                    ></img>
                  </div>
                </div>
                <div className="row g-0 w-100 mb-4">
                  <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                    <StyledTextInputField
                      value={email}
                      setValue={setEmail}
                      valueError={emailError}
                      setValueError={setEmailError}
                      label={"Email"}
                      required={true}
                      fullWidth={true}
                      shrink={true}
                      size={"small"}
                      id={"email-input"}
                    />
                  </div>
                </div>
                <div className="row g-0 w-100 mb-2">
                  <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                    <StyledPasswordInputField
                      value={password}
                      setValue={setPassword}
                      valueError={passwordError}
                      setValueError={setPasswordError}
                      label={"Password"}
                      required={true}
                      fullWidth={true}
                      shrink={true}
                      size={"small"}
                      id={"password-input"}
                    />
                  </div>
                </div>
                <div className="row g-0 w-100 mb-3">
                  <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                    <div className="row g-0 w-100">
                      <div className="align-self-end text-end">
                        {window.location.hostname !==
                          "app.investwithaqua.com" && (
                          <span
                            className="signUp"
                            style={{
                              cursor: "pointer",
                              paddingRight: "5px",
                              backgroundColor: "#0d47a1!important",
                              opacity: "1 !important",
                            }}
                            onClick={() => {
                              history.push("/feeder-signup");
                            }}
                          >
                            <u>Create New Account</u>
                          </span>
                        )}
                        <span
                          className="forgotPassword"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            history.push("/forgot-password");
                          }}
                        >
                          <u>Forgot Password?</u>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row g-0 w-100">
                  <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                    <div className="row g-0 w-100">
                      <div className="col ms-0 me-auto">
                        <div className="d-flex">
                          <button
                            type="button"
                            class="btn btn-lg btn-class"
                            onClick={() => signIn()}
                            disabled={loading}
                          >
                            {loading && (
                              <div className="row g-0 w-100">
                                <div className="col d-flex align-items-center">
                                  <span className="justify-self-start align-self-center">
                                    Sign In
                                  </span>
                                  <span
                                    class="ms-2 spinner-border spinner-border-sm justify-self-end align-self-center"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                </div>
                              </div>
                            )}
                            {!loading && <span>Sign In</span>}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-0 w-100 mt-3">
                  <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                    <div className="row g-0 w-100">
                      <div className="col-12">
                        <span className="forgotPassword">
                          Note: For first time users, please click the Create
                          New Account button to create a new account, where
                          you'll be prompted to enter your access code. Your
                          access code can't be used to sign in.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <AlertSnackBar
                  snackbarState={emailConfirmationSuccessful}
                  setSnackbarState={setEmailConfirmationSuccessful}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={5000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"success"}
                  message={
                    "Your account was successfully confirmed. You may now log in."
                  }
                />
                <AlertSnackBar
                  snackbarState={errorSnackBarState}
                  setSnackbarState={setErrorSnackbarState}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={10000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"error"}
                  message={
                    "Unfortunately, you will be unable to login because you have not confirmed your email. We tried resending a confirmation code to " +
                    email +
                    ", but we were unable to. Please confirm that you have signed up using a valid email that you still have access to."
                  }
                />
                <AlertSnackBar
                  snackbarState={invalidDomainError}
                  setSnackbarState={setInvalidDomainError}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={10000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"error"}
                  message={`You have access to the following domains: ${domains}`}
                />
                <AlertSnackBar
                  snackbarState={passwordResetSuccessful}
                  setSnackbarState={setPasswordResetSuccessful}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={5000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"success"}
                  message={"Password reset successfully."}
                />
                <AlertSnackBar
                  snackbarState={accountCreationSuccessful}
                  setSnackbarState={setAccountCreationSuccessful}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={5000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"success"}
                  message={
                    "Your account was successfully created. You may now log in."
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoginPage;
