import React, { useState, useEffect } from "react";
import Header from "../../../Components/GlobalComponents/Header.js";
import "./FundDetailsPageStyles/FundDetailsPageStyles.css";
import "react-circular-progressbar/dist/styles.css";
import NavLinksSection from "./NavLinksSection/NavLinksSection.js";
//import FundDetailsPageTopSection from "./FundDetailsPageTopSection/FundDetailsPageTopSection.js";
import FundDetailsPageMainContentSection from "./FundDetailsPageMainContentSection/FundDetailsPageMainContentSection.js";
import NewFundDetailsPageTopSection from "./NewFundDetailsPageTopSection/NewFundDetailsPageTopSection.js";
import { useParams } from "react-router-dom";
import GetFundInfo from "../../../Adapters/FundDetailsPageAdapters.js";
import Media from "react-media";
import PhoneFundDetailsPageTopSection from "./FundDetailsPageTopSection/PhoneFundDetailsPageTopSection/PhoneFundDetailsPageTopSection.js";
import PageLoading from "../../../Components/GlobalComponents/PageLoading/PageLoading.js";
import AccountPicker from "../../../GlobalComponents/AccountPicker/AccountPicker.js";
import GetAccountListAdapter from "../../../Adapters/GetAccountListAdapter.js";
import { isFeederAPI } from "../../../Api/FundCardApi.js";
import FlorenceCapDetails from "./NewFundDetailsPageTopSection/FlorenceCapDetails.js";
import LVDetails from "./NewFundDetailsPageTopSection/LVDetails.js";
import QvinDetails from "./NewFundDetailsPageTopSection/QvinDetails.js";
import WandDetails from "./NewFundDetailsPageTopSection/WandDetails.js";
import AssemblyDetails from "./NewFundDetailsPageTopSection/AssemblyDetails.js";
import SunshineLakeDetails from "./NewFundDetailsPageTopSection/SunshineLakeDetails.js";
import NscaleDetails from "./NewFundDetailsPageTopSection/NscaleDetails.js";
import JukeboxDetails from "./NewFundDetailsPageTopSection/JukeboxDetails.js";
import DOrbitDetails from "./NewFundDetailsPageTopSection/DOrbitDetails.js";

function FundDetailsPage() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const { fund } = GetFundInfo(id, setLoading);
  const [dbFund, setDBFund] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const { accounts } = GetAccountListAdapter(refresh, setLoading);
  const [feeder, setFeeder] = useState(null);
  const [initialNum, setInitialNum] = useState(0);
  const hostName = window.location.hostname;

  useEffect(() => {
    setDBFund(fund);
  }, [fund]);

  useEffect(() => {
    console.log("Host name is: " + hostName);
  }, []);

  useEffect(() => {
    async function isFeeder() {
      var response = await isFeederAPI();

      console.log("Response is: " + JSON.stringify(response.data));

      if (response.data.msg === true) {
        setFeeder(true);
      } else if (response.data.msg === false) {
        setFeeder(false);
      }
    }
    isFeeder();
    // console.log(accountLinkSuccessful);
  }, [initialNum]);

  return loading ? (
    <PageLoading />
  ) : (
    <React.Fragment>
      <Header activePage={"invest"}></Header>
      <div className="mt-5 pt-5"></div>
      <div className="container-xxl contain mt-xxl-2 mt-xl-2 mt-lg-2 mt-md-2 mt-sm-2 mt-4 pb-5">
        {!loading && (
          <div className="row g-0 w-100 justify-content-end mb-3">
            <AccountPicker width={350} accounts={accounts} />
          </div>
        )}
        <NavLinksSection />
        <Media
          queries={{
            medium: { maxWidth: 991 },
            large: { minWidth: 992 },
          }}
        >
          {(matches) =>
            matches.medium ? (
              <PhoneFundDetailsPageTopSection
                fund={dbFund}
                stage={dbFund !== null ? dbFund.investmentStatus : ""}
              />
            ) : fund && fund.link === "spv_vii" ? (
              <FlorenceCapDetails
                fund={dbFund}
                stage={dbFund !== null ? dbFund.investmentStatus : ""}
                isFeeder={feeder}
              />
            ) : fund && fund.link === "wand_ai" ? (
              <WandDetails
                fund={dbFund}
                stage={dbFund !== null ? dbFund.investmentStatus : ""}
                isFeeder={feeder}
              />
            ) : fund && fund.link === "spv_viii" ? (
              <QvinDetails
                fund={dbFund}
                stage={dbFund !== null ? dbFund.investmentStatus : ""}
                isFeeder={feeder}
              />
            ) : fund && fund.link === "laude_venture_i" ? (
              <LVDetails
                fund={dbFund}
                stage={dbFund !== null ? dbFund.investmentStatus : ""}
                isFeeder={feeder}
              />
            ) : fund && fund.link === "spv_xi" ? (
              <AssemblyDetails
                fund={dbFund}
                stage={dbFund !== null ? dbFund.investmentStatus : ""}
                isFeeder={feeder}
              />
            ) : fund && fund.link === "slai_i" ? (
              <SunshineLakeDetails
                fund={dbFund}
                stage={dbFund !== null ? dbFund.investmentStatus : ""}
                isFeeder={feeder}
              />
            ) : fund && fund.link === "spv_x" ? (
              <NscaleDetails
                fund={dbFund}
                stage={dbFund !== null ? dbFund.investmentStatus : ""}
                isFeeder={feeder}
              />
            ) : fund && fund.link === "spv_xii" ? (
              <JukeboxDetails
                fund={dbFund}
                stage={dbFund !== null ? dbFund.investmentStatus : ""}
                isFeeder={feeder}
              />
            ) : fund && fund.link === "icp" ? (
              <DOrbitDetails
                fund={dbFund}
                stage={dbFund !== null ? dbFund.investmentStatus : ""}
                isFeeder={feeder}
              />
            ) : (
              <NewFundDetailsPageTopSection
                fund={dbFund}
                stage={dbFund !== null ? dbFund.investmentStatus : ""}
                isFeeder={feeder}
              />
            )
          }
        </Media>
        <FundDetailsPageMainContentSection fund={fund} />
      </div>
    </React.Fragment>
  );
}
export default FundDetailsPage;
