import React from "react";
import "./Styles.scss";

function SunshineLakeTargetPerformanceSection({minimum}) {
  return (
    <div class="col-12">
      <div class="col-12">
        <div class="row ms-3 me-3">
          <div class="two-by-two-grid-new rounded horizontal-border-new">
            <div class="row border-between vertical-border-new">
              <div class="col-6 two-by-two-grid-item-new">
                {" "}
                <div class="fund-target-header-text-new">
                  Unicorns backed
                </div>{" "}
                <span class="info-text-new">{"10"}</span>
              </div>
              <div class="col-6 two-by-two-grid-item-new">
                {" "}
                <div class="fund-target-header-text-new">Strategy</div>{" "}
                <span class="info-text-new">Venture</span>
              </div>
            </div>
            <div class="row border-between">
              <div class="col-6 two-by-two-grid-item-new">
                <div class="fund-target-header-text-new">Target Fund Size</div>
                <span class="info-text-new">{"$50M"}</span>
              </div>
              <div class="col-6 two-by-two-grid-item-new">
                <div class="fund-target-header-text-new">Minimum</div>
                <span class="info-text-new">{"$" + minimum.toLocaleString("en-US")}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row g-0 mb-3">
          <div class="row gx-3"></div>
        </div>
      </div>
    </div>
  );
}
export default SunshineLakeTargetPerformanceSection;
